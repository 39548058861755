import type { FlattenSimpleInterpolation } from 'styled-components'
import s, { css } from 'styled-components'
// @ts-expect-error styled is not typed with default, resolves ESM issue found in Nextjs
const styled: typeof s = typeof s === 'object' && 'default' in s ? s.default : s

import { applyFocus, srOnly } from '@vfuk/core-mixins'

export const StarRadio = styled.input(
  (): FlattenSimpleInterpolation => css`
    position: absolute;
    opacity: 0;
    margin: 0;
    left: 0;
    top: 0;

    & + label {
      ${applyFocus()}
    }
  `,
)

export const HiddenElement = styled.span(
  (): FlattenSimpleInterpolation => css`
    ${srOnly()}
  `,
)

export const StarLabel = styled.label(
  (): FlattenSimpleInterpolation => css`
    display: inline-block;
  `,
)

export const IconWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    cursor: pointer;
    width: 24px;
    height: 24px;

    svg {
      width: 100%;
      height: 100%;
    }
  `,
)
