import type { ReactNode } from 'react'
import React, { PureComponent, Fragment } from 'react'
import { withTranslation } from 'react-i18next'

import { getDataSelector } from '@vfuk/core-base-props'
import type { StarProps } from '@vfuk/core-star-rating'
import { Star } from '@vfuk/core-star-rating'

import * as Styled from './styles/StarRatingInput.style'

import type { StarRatingInputProps, Values, StarRatingInputState } from './StarRatingInput.types'

export class StarRatingInput extends PureComponent<StarRatingInputProps, StarRatingInputState> {
  public static valuesOptions: Values[] = [1, 2, 3, 4, 5]

  public componentName = 'StarRatingInput'

  public state: StarRatingInputState = {
    isHovered: false,
    hoveredValue: 0,
  }

  public static defaultProps: Partial<StarRatingInputProps> = {
    required: false,
  }

  private handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (this.props.onChange) {
      this.props.onChange(parseInt(event.target.value, 10) as Values)
    }
  }

  private handleOnBlur = (): void => {
    if (this.props.onBlur) {
      this.props.onBlur(this.props.value)
    }
  }

  private getInputIcon = (value: number): StarProps['type'] => {
    const activeValue = this.state.isHovered ? this.state.hoveredValue : this.props.value
    return value <= activeValue ? 'full' : 'empty'
  }

  private onLabelHover = (value: Values): void => {
    this.setState({
      isHovered: true,
      hoveredValue: value,
    })
  }

  private onLabelRemoveHover = (): void => {
    this.setState({
      isHovered: false,
      hoveredValue: 0,
    })
  }

  public render(): ReactNode {
    const id = this.props.id ? this.props.id : this.props.name
    return (
      <fieldset
        id={id}
        name={this.props.name}
        onBlur={this.handleOnBlur}
        {...this.props.dataAttributes}
        data-selector={getDataSelector(this.props.dataSelectorPrefix)}
        data-component-name={this.componentName}
      >
        <Styled.HiddenElement as='legend'>{this.props.name}</Styled.HiddenElement>
        {StarRatingInput.valuesOptions.map((value) => {
          const starId = `${id}-${value}`
          const iconName = this.getInputIcon(value)
          return (
            <Fragment key={starId}>
              <Styled.StarRadio
                type='radio'
                required={this.props.required}
                id={starId}
                onChange={this.handleOnChange}
                checked={this.props.value === value}
                name={starId}
                value={value}
                aria-describedby={this.props.describedBy}
                {...this.props.dataAttributes}
                data-selector={getDataSelector(this.props.dataSelectorPrefix, 'input')}
              />
              <Styled.StarLabel
                htmlFor={starId}
                onMouseEnter={(): void => {
                  this.onLabelHover(value)
                }}
                onMouseLeave={this.onLabelRemoveHover}
                data-selector={getDataSelector(this.props.dataSelectorPrefix, 'label')}
              >
                <Styled.IconWrapper>
                  <Star type={iconName} />
                </Styled.IconWrapper>
                <Styled.HiddenElement data-selector={getDataSelector(this.props.dataSelectorPrefix, 'hidden-element')}>
                  {this.props.t!('Iteration', { iterator: `${this.props.name} ${value}`, array: StarRatingInput.valuesOptions.length })}
                </Styled.HiddenElement>
              </Styled.StarLabel>
            </Fragment>
          )
        })}
      </fieldset>
    )
  }
}

export default withTranslation()(StarRatingInput)
