import type { FC, ReactElement } from 'react'
import React from 'react'

import InlineLink from '@vfuk/core-inline-link'
import Paragraph from '@vfuk/core-paragraph'
import { getDataSelector } from '@vfuk/core-base-props'

import getStarType from './helpers/getStarType'

import * as Styled from './styles/StarRating.style'

import type { StarRatingProps } from './StarRating.types'

import Star from './components/Star'

const StarRating: FC<StarRatingProps> = ({
  rating,
  text,
  link,
  orientation = 'horizontal',
  inverse = false,
  size = 1,
  srText,
  id,
  dataSelectorPrefix,
  dataAttributes,
}: StarRatingProps): ReactElement => {
  const componentName = 'StarRating'

  const starRatings = [0, 1, 2, 3, 4]

  return (
    <Styled.StarRating
      orientation={orientation}
      id={id}
      data-selector={getDataSelector(dataSelectorPrefix)}
      data-component-name={componentName}
      {...dataAttributes}
    >
      {starRatings.map((starRating, index) => {
        return (
          <Styled.IconWrapper key={index} size={size}>
            <Star
              type={getStarType(starRating, rating)}
              inverse={inverse}
              dataSelectorPrefix={getDataSelector(dataSelectorPrefix, `star-${index}`)}
            />
          </Styled.IconWrapper>
        )
      })}
      {text && (
        <Styled.TextWrapper orientation={orientation} size={size}>
          {link ? (
            <Paragraph size={1} dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'text')}>
              <InlineLink {...link} srText={srText} inverse={inverse} dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'link')}>
                {text}
              </InlineLink>
            </Paragraph>
          ) : (
            <Paragraph size={size === 3 ? 2 : 1} inverse={inverse} dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'text')}>
              {text}
            </Paragraph>
          )}
        </Styled.TextWrapper>
      )}
    </Styled.StarRating>
  )
}

export default StarRating
