import type { FC, ReactElement } from 'react'
import { useTheme } from 'styled-components'
import React from 'react'
import { v4 as uuid } from 'uuid'

import { getDataSelector } from '@vfuk/core-base-props'

import type { Theme } from '@vfuk/core-themes'

import type { StarProps } from './Star.types'

const Star: FC<StarProps> = ({ type, inverse, dataSelectorPrefix }: StarProps): ReactElement => {
  const gradientId = uuid()

  const { color, inverseColor } = useTheme() as Theme

  const starFill = (): string => {
    if (type === 'full') {
      return inverse ? inverseColor.secondary1.default : color.secondary1.default
    }
    return 'none'
  }

  return (
    <svg
      aria-hidden='true'
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill={starFill()}
      xmlns='http://www.w3.org/2000/svg'
      data-selector={getDataSelector(dataSelectorPrefix)}
    >
      {type === 'halfFull' ? (
        <>
          <path
            d='M1.74829 6.67499L6.07579 5.95999L7.93912 1.98999L9.95662 5.88416L14.3083 6.42999L11.2283 9.55166L12.0533 13.8592L8.13246 11.8942L4.29162 14.01L4.94829 9.67416L1.74829 6.67499Z'
            fill={`url(#${gradientId})`}
            stroke={inverse ? inverseColor.secondary1.default : color.secondary1.default}
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <defs>
            <linearGradient id={gradientId} x1='1.33329' y1='2' x2='15' y2='2' gradientUnits='userSpaceOnUse'>
              <stop offset='0.463542' stopColor={inverse ? inverseColor.secondary1.default : color.secondary1.default} />
              <stop offset='0.463642' stopColor='white' stopOpacity='0' />
            </linearGradient>
          </defs>
        </>
      ) : (
        <path
          d='M1.74829 6.67499L6.07579 5.95999L7.93912 1.98999L9.95662 5.88416L14.3083 6.42999L11.2283 9.55166L12.0533 13.8592L8.13246 11.8942L4.29162 14.01L4.94829 9.67416L1.74829 6.67499Z'
          fill=''
          stroke={inverse ? inverseColor.secondary1.default : color.secondary1.default}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      )}
    </svg>
  )
}

export default Star
