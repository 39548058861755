import type { FlattenSimpleInterpolation } from 'styled-components'
import s, { css } from 'styled-components'
// @ts-expect-error styled is not typed with default, resolves ESM issue found in Nextjs
const styled: typeof s = typeof s === 'object' && 'default' in s ? s.default : s

import { spacing } from '@vfuk/core-mixins'

import type { StyledStarRatingProps } from './StarRating.style.types'

export const StarRating = styled.section<StyledStarRatingProps>(
  (props): FlattenSimpleInterpolation => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    ${props.orientation === 'vertical' &&
    css`
      flex-wrap: wrap;
    `}
  `,
)

export const TextWrapper = styled.span<StyledStarRatingProps>(
  (props): FlattenSimpleInterpolation => css`
    display: inline-block;

    ${props.orientation === 'horizontal' &&
    css`
      ${spacing('margin-left', 2)};
    `}

    ${props.orientation === 'vertical' &&
    css`
      flex-basis: 100%;
      margin-left: 0;
      ${spacing('margin-top', 2)};
    `}

  ${props.size === 1 &&
    css`
      ${spacing('margin-top', 2)}
    `}
  `,
)

export const IconWrapper = styled.span<StyledStarRatingProps>(
  (props): FlattenSimpleInterpolation => css`
    width: 16px;
    height: 16px;

    svg {
      width: 16px;
      height: 16px;
    }

    ${props.size === 3 &&
    `
    width: 24px;
    height: 24px;

    svg {
      width: 24px;
      height: 24px;
    }
  `}

    ${props.size === 2 &&
    `
    width: 20px;
    height: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
  `}
  `,
)
