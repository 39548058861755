/* eslint-disable camelcase */
import axios from "axios";
import { application, apiUrl, envId, ingressURL } from "../settings.json";
import { getMiddlewareBaseUrl } from "utils/helpers";

const REQUEST_TIME_OUT = 15000; // only wait for 15s

class ConfigService {
  fetchConfig = async () => {
    const headers = {
      "content-type": "application/json",
    };

    const url = `${apiUrl}/coreapp/configuration/${window.REACT_APP_ENV_ID || envId}/${application}`;
    const { data } = await axios.create()({
      method: "get",
      url,
      headers,
    });
    return {
      ...data,
      fetchDate: Date.now(),
    };
  };

  fetchTaggingSheet = (url) => {
    fetch(`${url}`, {
      method: "GET",
      withCredentials: true,
      headers: { "content-type": "application/json" },
      mode: "no-cors",
    })
      .then((response) => {
        console.log("response from fetch ", response);
        return response;
      })
      .catch((e) => {
        console.log(e);
      });
  };
}

const inst = new ConfigService();
export default inst;
